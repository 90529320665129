import moment from 'moment';
import { getStatisticList } from '@/services/statistics';

import type { PresetsItem, StatisticsItem } from '@/pages/statistics/type';

export interface StatisticsState {
  loading: boolean;
  currentKey: number;
  presets: PresetsItem[];
  statisticList: StatisticsItem[];
}

export default {
  namespace: 'statistics',
  state: {
    loading: false,
    currentKey: 0,
    statisticList: [],
    presets: [
      {
        key: 0,
        label: 'Past 7 Days',
        from: moment().subtract(7, 'days').format('MM/DD/YYYY'),
        to: moment().format('MM/DD/YYYY'),
      },
      {
        key: 1,
        label: 'Past 30 Days',
        from: moment().subtract(30, 'days').format('MM/DD/YYYY'),
        to: moment().format('MM/DD/YYYY'),
      },
      {
        key: 9,
        label: 'Yesterday',
        from: moment().subtract(1, 'days').format('MM/DD/YYYY'),
        to: moment().subtract(1, 'days').format('MM/DD/YYYY'),
      },
      {
        key: 2,
        label: 'Today',
        from: moment().format('MM/DD/YYYY'),
        to: moment().format('MM/DD/YYYY'),
      },
    ],
  },
  effects: {
    *setCurrentKey({ values }: any, { put }: any) {
      try {
        yield put({ type: 'SET_CURRENT_KEY', data: values })
        yield put({ type: 'getStatisticList' })
      } catch (err) {
        return Promise.reject(err);
      }
    },
    *getStatisticList({ values }: any, { call, put, select }: any) {
      try {
        yield put({ type: 'SET_LOADING', data: true });

        const { statistics } = yield select();
        const item = statistics.presets.find((i: PresetsItem) => i.key === statistics.currentKey)
        const resp = yield call(getStatisticList, item.from, item.to);

        yield put({ type: 'SET_STATISTIC_LIST', data: resp })
        yield put({ type: 'SET_LOADING', data: false });

      } catch (err) {
        yield put({ type: 'SET_LOADING', data: false });
        return Promise.reject(err);
      }
    },
  },
  reducers: {
    SET_LOADING(state: StatisticsState,  { data }: unknown) {
      return {
        ...state,
        loading: data,
      }
    },
    SET_CURRENT_KEY(state: StatisticsState,  { data }: unknown) {
      return {
        ...state,
        currentKey: data,
      }
    },
    SET_STATISTIC_LIST(state: StatisticsState,  { data }: unknown) {
      return {
        ...state,
        statisticList: data,
      }
    },
  }
}



