declare module '@/conf/env';
const dev = process.env.NODE_ENV !== 'production';
const ENV = dev ? 'development' : 'production';

const envConfig = {
  ENV,
  // FILE_URL,
  APP_ENV: `${REACT_APP_ENV}`,
  API_GATEWAY: `${GATEWAY_HOST}`,
  API_WSW: `${WSW2_HOST}`,
  INTERNAL_HOST: `${INTERNAL_HOST}`,
};

export default envConfig;
