// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/runner/work/internal-tools-web/internal-tools-web/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/home/runner/work/internal-tools-web/internal-tools-web/src/layouts/BasicLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "importCSV",
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__importCSV' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/importCSV'), loading: LoadingComponent}),
        "menu": {
          "name": "Import CSV"
        },
        "exact": true
      },
      {
        "name": "Companys",
        "path": "/companys",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companys' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/companys'), loading: LoadingComponent}),
        "menu": {
          "name": "Companies"
        },
        "exact": true
      },
      {
        "name": "Employees",
        "path": "/employees",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__employees' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/employees'), loading: LoadingComponent}),
        "menu": {
          "name": "Users"
        },
        "exact": true
      },
      {
        "name": "BusinessTypes",
        "path": "/businesstypes",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__businesstypes' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/businesstypes'), loading: LoadingComponent}),
        "menu": {
          "name": "Business Types"
        },
        "exact": true
      },
      {
        "name": "PaymentTerms",
        "path": "/paymentTerms",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__paymentTerms' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/paymentTerms'), loading: LoadingComponent}),
        "menu": {
          "name": "Payment Terms"
        },
        "exact": true
      },
      {
        "name": "Transactions",
        "path": "/transactions",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transactions' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/transactions'), loading: LoadingComponent}),
        "menu": {
          "name": "Transactions"
        },
        "exact": true
      },
      {
        "name": "Statistics",
        "path": "/statistics",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__statistics' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/statistics'), loading: LoadingComponent}),
        "menu": {
          "name": "Statistics"
        },
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/home/runner/work/internal-tools-web/internal-tools-web/src/layouts/BasicLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "importCSV",
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__importCSV' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/importCSV'), loading: LoadingComponent}),
        "menu": {
          "name": "Import CSV"
        },
        "exact": true
      },
      {
        "name": "Companys",
        "path": "/companys",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companys' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/companys'), loading: LoadingComponent}),
        "menu": {
          "name": "Companies"
        },
        "exact": true
      },
      {
        "name": "Employees",
        "path": "/employees",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__employees' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/employees'), loading: LoadingComponent}),
        "menu": {
          "name": "Users"
        },
        "exact": true
      },
      {
        "name": "BusinessTypes",
        "path": "/businesstypes",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__businesstypes' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/businesstypes'), loading: LoadingComponent}),
        "menu": {
          "name": "Business Types"
        },
        "exact": true
      },
      {
        "name": "PaymentTerms",
        "path": "/paymentTerms",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__paymentTerms' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/paymentTerms'), loading: LoadingComponent}),
        "menu": {
          "name": "Payment Terms"
        },
        "exact": true
      },
      {
        "name": "Transactions",
        "path": "/transactions",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__transactions' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/transactions'), loading: LoadingComponent}),
        "menu": {
          "name": "Transactions"
        },
        "exact": true
      },
      {
        "name": "Statistics",
        "path": "/statistics",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__statistics' */'/home/runner/work/internal-tools-web/internal-tools-web/src/pages/statistics'), loading: LoadingComponent}),
        "menu": {
          "name": "Statistics"
        },
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
