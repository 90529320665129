import { Settings as ProSettings } from '@ant-design/pro-layout';

const proSettings: ProSettings = {
  title: 'Grubmarket',
  navTheme: 'dark',
  primaryColor: '#1c6e31',
  layout: 'side',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  menu: {},
};

export default proSettings;
