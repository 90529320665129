/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';
import { notification } from 'antd';
import { getDvaApp } from 'umi';
import { env, APIS } from '@/conf';
import { get } from 'lodash';

/*const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};*/

/**
 * 异常处理程序
 */
const errorHandler = async (error: { response: Response }) => {
  const { response } = error;
  if (response && response.status) {
    //const errorText = codeMessage[response.status] || response.statusText;
    const { url } = response;
    if (response.status === 401) {
      getDvaApp()._store.dispatch({
        type: 'app/logout',
        reload: true,
      });
    }

    // if (response.status === 409) {
    //   notification.error({
    //     description: 'this email already exist',
    //     message: 'this email already exist',
    //   });
    // }

    if (url.includes(APIS.LOGIN.url)) {
      notification.error({
        message: 'Login failed',
        description: 'The account or password is incorrect',
      });
      throw new Error('Login failed');
    }

    if (url.includes('check-login')) {
      notification.error({
        message: 'Login failed',
        description: 'SUPERADMIN required',
      });
      throw new Error('Login failed');
    }

    const data = await response.clone().text();
    const resp = data ? JSON.parse(data) : { message: 'Request failed' };
    notification.error({
      message: resp.message || get(resp, 'body.message') || 'Request failed',
    });

    return Promise.reject(resp);

    // notification.error({
    //   message: `error ${status}: ${url}`,
    //   // description: errorText,
    // });
    //throw `error ${status}: ${url}`;
  } else if (!response) {
    notification.error({
      description: 'Your network is abnormal and cannot connect to the server',
      message: 'network anomaly',
    });
  }
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler, // 默认错误处理
});

request.interceptors.response.use((response: { status: number; clone: () => any }) => {
  if (response.status === 200) {
    const clone = response.clone();
    clone.text().then((data: string) => {
      const resp = JSON.parse(data);
      if (resp.status !== 200 && resp.statusCodeValue !== 200) {
        if (resp.access_token) return;
        notification.error({
          message: resp.message || get(resp, 'body.message') || 'Request failed',
        });
      }
    });
  }
  return response;
});

request.interceptors.request.use((u: any, options: { headers: any }) => {
  const headers = { ...options.headers };
  if (localStorage.token) {
    headers.Authorization = `Bearer ${localStorage.token}`;
  } else {
    headers.Authorization = `Basic d3N3Om9hdXRoMg==`;
  }

  let url = u;

  let baseUrl = env.INTERNAL_HOST;

  if (url.startsWith('/oauth/')) {
    baseUrl = env.API_GATEWAY;
  }

  if (url.startsWith('/wholesale/')) {
    url = `/api/v1${url}`;
    baseUrl = env.API_WSW;
  }

  // console.log(`${baseUrl}${url}`);

  return {
    url: `${baseUrl}${url}`,
    options: {
      ...options,
      headers,
    },
  };
});

export default request;
