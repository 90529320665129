import request from '@/utils/request';
import api from '@/conf/api';
import { get } from 'lodash';

export function getStatisticList(_from: string, _to: string) {
  return request.get(api.STATISTICS.url, {
    params: {
      activities: 'login, salesOrderCreation, purchaseOrderCreation',
      from: _from,
      to: _to,
    },
  })
    .then((res: unknown) => get(res, 'data', []));
}
