import generateApi, { IAPI, IApiConfig } from './generateApi';

const apiConfig: IApiConfig = {
  LOGIN: '/oauth/token',
  // GET_PROFILE: '/wsw-hr/api/user/me',
  GET_PROFILE: '/api/tool/check-login',

  GET_AREA: '/platform/system/area/getByPid',

  FILE: {
    GET_UPLOAD_TOKEN: 'xxxxxxxxxxxxxx',
  },

  COMPANY: {
    QUERY_COMPANYS: '/api/tool/companies',
    QUERY_WHOLESALERS: '/api/tool/wholesalers',
    CREATE_COMPANY: '/api/tool/company',
  },

  EMPLOYEE: {
    QUERY_EMPLOYEES: '/api/tool/users',
    CREATE_EMPLOYEE: '/api/tool/company/:companyId/user',
    UPDATE_EMAIL: '/api/tool/user/:userId/email',
    UPDATE_ROLE: '/api/tool/user/:userId/companyId/:companyId/role/:role',
    UPDATE_PASSWD: '/api/tool/user/:userId/password',
    QUERY_CLIENT_USERS: '/api/tool/client-users',
  },

  IMPORT_SVC: {
    GET_COMPANYS: '/api/tool/wholesaler-companies',
    PRODUCT: '/api/tool/company/:companyId/import-product',
    CLIENT: '/api/tool/company/:companyId/import-client',
    VENDOR_PRODUCTS: '/api/tool/company/:companyId/import-vendor-product-list',
    CONTACTS: '/api/tool/company/:companyId/import-contact',
    CATEGORIES: '/api/tool/company/:companyId/import-category',
    DOWNLOAD_CONTACTS: '/api/tool/company/:companyId/download-contacts',
    DOWNLOAD_CLIENT: '/api/tool/company/:companyId/download-client',
    DOWNLOAD_PRODUCT: '/api/tool/company/:companyId/download-product',
    DOWNLOAD_ADDRESSES: '/api/tool/company/:companyId/download-addresses',
    CUSTOMER_ADDRESS: '/api/tool/company/:companyId/import-addresses',
  },

  BUSINESS_TYPE: {
    QUERY_BUSINESS_TYPES: '/api/tool/business-types',
    ADD_BUSINESS_TYPE: '/api/tool/company/:companyId/add-business-type',
  },

  PAYMENT_TERM: {
    QUERY_PAYMENT_TERMS: '/api/tool/payment-terms',
  },

  TRANSACTIONS: {
    QUERY_TRANS: '/api/tool/get-transactions',
  },

  STATISTICS: '/api/tool/company/activity-statistics',
};

const APIList = generateApi(apiConfig) as IAPILIST;
export default APIList;

interface IAPILIST {
  LOGIN: IAPI;
  GET_PROFILE: IAPI;

  GET_AREA: IAPI;

  FILE: {
    GET_UPLOAD_TOKEN: IAPI;
  };

  COMPANY: {
    QUERY_WHOLESALERS: IAPI;
    QUERY_COMPANYS: IAPI;
    CREATE_COMPANY: IAPI;
  };

  EMPLOYEE: {
    QUERY_CLIENT_USERS: IAPI;
    QUERY_EMPLOYEES: IAPI;
    CREATE_EMPLOYEE: IAPI;
    UPDATE_EMAIL: IAPI;
    UPDATE_ROLE: IAPI;
    UPDATE_PASSWD: IAPI;
  };

  IMPORT_SVC: {
    GET_COMPANYS: IAPI;
    PRODUCT: IAPI;
    CLIENT: IAPI;
    VENDOR_PRODUCTS: IAPI;
    CONTACTS: IAPI;
    CATEGORIES: IAPI;
    DOWNLOAD_CONTACTS: IAPI;
    DOWNLOAD_CLIENT: IAPI;
    DOWNLOAD_PRODUCT: IAPI;
    DOWNLOAD_ADDRESS: IAPI;
    CUSTOMER_ADDRESS: IAPI;
  };

  BUSINESS_TYPE: {
    QUERY_BUSINESS_TYPES: IAPI;
    ADD_BUSINESS_TYPE: IAPI;
  };

  PAYMENT_TERM: {
    QUERY_PAYMENT_TERMS: IAPI;
  };

  TRANSACTIONS: {
    QUERY_TRANS: IAPI;
  };

  STATISTICS: IAPI;
}
