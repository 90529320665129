import api from '@/conf/api';
import request from '@/utils/request';
import getParameterByName from '@/utils/getParameterByName';
import { getProfile } from '@/services/user';

type ProfileProps = {}

export type IAppState = {
  isLogin: boolean
  initialized: boolean
  profileId: number
  profile: ProfileProps
  loginInfo: any
}

const state: IAppState = {
  isLogin: Boolean(localStorage.token),
  profileId: localStorage.id,
  profile: {},
  initialized: false,
  loginInfo: {},
}

export default {
  namespace: 'app',
  state,
  subscriptions: {
    setup({ dispatch }: any) {
      console.log('App Start !!')
      dispatch({ type: 'init' })
    }
  },
  effects: {
    *init(_: any, { call, put, select }: any) {
      if (getParameterByName('jwt')) return
      const { app } = yield select()
      // no token unlogin
      if (!app.isLogin) return yield put({ type: 'DONE' })

      try {
        const { data: profile } = yield call(getProfile)
        console.log(profile)
        yield put({
          type: 'UPDATE',
          data: {
            profile
          }
        })
      } catch (err) {
        console.log(err)
      }

      yield put({ type: 'DONE' })
    },
    *login({ replace = false, values }: any, { call, put }: any) {
      try {
        const resp = yield call(login, values)
        if (resp?.access_token) {
          localStorage.setItem('token', resp?.access_token)
          yield put({ type: 'SAVE', resp })
          const { data: profile } = yield call(getProfile)
          yield put({
            type: 'UPDATE',
            data: { profile, isLogin: true },
          });
          // window.location.reload()
        }
      } catch (err) {
        localStorage.removeItem('token');
        return new Error(err.message)
      }
      // yield put({ type: 'SAVE', resp })

      // if (replace) {
      //   window.location.replace(url)
      // } else {
      //   window.location.href = url
      // }
    },
    *logout({ reload = false }, { put }: any) {
      // yield call(logout, { jltToken: window.localStorage.token })
      localStorage.clear()
      yield put({ type: 'UPDATE', data: { profile: {} } })
      if (reload) {
        window.location.reload()
      }
    },
  },
  reducers: {
    UPDATE(state: IAppState, { data }: any) {
      return {
        ...state,
        ...data
      }
    },
    SAVE(state: IAppState) {
      return {
        ...state,
        loginInfo: { success: true }
      }
    },
    DONE(state: IAppState) {
      state.initialized = true
      return state
    },
  }
}

function login(values: any) {
  const formData = new FormData();
  formData.append('grant_type', 'password')
  formData.append('username', values.username)
  formData.append('password', values.password)
  formData.append('scope', 'all')
  return request.post(api.LOGIN.url, { data: formData, requestType: 'form' })
}
